html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #626163;
}

.App {
  text-align: center;
}

.h1, h1{
  font-size: calc(1.375rem + 1.5vw);
  font-weight: bold;
}
@media (min-width: 1200px) {
.h1, h1{
  font-size: 2.5rem;
  }
}

.h2, h2,.h3, h3,.h4, h4,.h5, h5,.h6, h6,.h7, h7,.h8, h8{
  margin: 0px;
  font-size: calc(1.0rem + 1.5vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  .h2, h2,.h3, h3,.h4, h4,.h5, h5,.h6,h6,.h7, h7,.h8, h8{
  font-size: 2rem;
  }
}

a {
  color: #626163;
  font-weight: 500;
}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}

.topMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  color: white;
  background-color: black;
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: bold;
}

.topMenu a {
  color: white;
}
.topMenuNav {
  display: flex;
  width: 100%;
  justify-content: right;
  color: white;
  background-color: black;
  height: 40px;
}
.topMenuNav a {
  color: white;
  margin: 1%;
  margin-top: 0rem;
  font-weight: bold;
}
.topMenuNav a:link {
    border-bottom: 2px solid white;
  }

.topMenu p{
  font-size: 6.0rem;
  margin-bottom: 0rem;
  font-weight: bold;
}

#logo{
  margin-top: 0px;
}

.info{
  width: 70%;
  margin: auto;
  font-size: large;
  padding: 10px;
  text-align: center;
}

.explainer{
  margin: auto;
  width: 60%;
}
.explainer > div {
  margin-left: 20%;
}

ol li { 
text-align: left;
}


@media only screen and (max-width: 800px) {
  .topMenu p{
    font-size: 4.5rem;
    margin-bottom: 0rem;
  }
}

@media only screen and (max-width: 600px) {
  .topMenu p{
    font-size: 3.75rem;
    margin-bottom: 0rem;
  }
  .explainer{
    width: 100%;
  }
  .explainer > div {
    margin-left: 0px;
  }  
}

@media only screen and (max-width: 500px) {
  .topMenu p{
    font-size: 3rem;
    margin-bottom: 0rem;
  }
  #logo{
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .topMenu p{
    font-size: 2.5rem;
    margin-bottom: 0rem;
  }
  #logo{
    display: none;
  }
}

.pageHeader{
  padding: 30px;
  margin-top: 50px;
}

.blur{
  opacity: 0.5;
  filter: alpha(opacity = 50); /* required for opacity to work in IE */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal div {
  justify-content: center;
  align-items: center;
}
 
.modal button {
  margin: 5px;
  padding: 8px 20px;
  border: none;
  cursor: pointer;
}

#ads_m{
  display:none;
}
#ads_sm{
  display:none;
}

@media only screen and (max-width: 1000px) {
  #ads {
    display:none;
  }
  #ads_m{
    display:block;
  }
  #ads_sm{
    display:none;
  }
}
@media only screen and (max-width: 650px) {
  #ads {
    display:none;
  }
  #ads_m{
    display:none;
  }
  #ads_sm{
    display:block;
  }
}



.privacy_h{
  font-size: 1.7rem;
  font-weight: 500;
}