body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #626163;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.results {
  display: inline-block;
  padding: 5px 20px; 
}

.dropzone {
  width: 800px !important;
  height: 400px !important;
  font-size: 20px;
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 0px;
  position: relative;
}
.dropzone-file-types {
  padding-top: 60px;
}
.dropzone-file-size {
  margin: 30px;
}

.file-selected-wrapper{
  padding: 10px;
  text-align: center;
}
.selected-file {
  padding: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 800px) {
  .dropzone {
    width: 600px !important;
    height: 300px !important;
    font-size: 20px;
    text-align: center;
    padding: 5px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    cursor: pointer;
    margin: auto;
    margin-top: 5px;
	}
  .dropzone-file-types {
    padding-top: 30px;
  } 
  .dropzone-file-size {
    margin: 15px;
  }
  .file-selected-wrapper{
    padding: 5px;
    text-align: center;
  }
  .selected-file {
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 600px) {
  .dropzone {
    width: 400px !important;
    height: 200px !important;
    font-size: 15px;
    text-align: center;
    padding: 0px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    cursor: pointer;
    margin: auto;
    margin-top: 0px;
	}
  .dropzone-file-types {
    padding-top: 15px;
  } 
  .dropzone-file-size {
    margin: 5px;
  }
  .file-selected-wrapper{
    padding: 5px;
    text-align: center;
  }
  .selected-file {
    padding: 0px;
    font-size: 15px;
    font-weight: bold;
  }
}

.dropzone-thumbsOutter {
  width: 50%;
  margin: auto !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.dropzone-thumbsContainer {
  display: flex;
  flex-Direction: row;
  flex-Wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px !important;
}
.dropzone-thumb {
  display: inline-flex;
  border: 1px solid #eaeaea;
  max-height: 300px;
  max-width: 300px;
  box-Sizing: border-box;
  margin: 10px !important;
  .dropzone-thumbInner {
    display: flex;
    justify-content: center; /* align horizontal */
    min-Width: 0;
    overflow: hidden;
    margin: 10px !important;
  }
}

.thumbsOutter {
  width: 50%;
  margin: auto !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbsContainer {
  display: flex;
  flex-Direction: row;
  flex-Wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px !important;
}
.thumb {
  display: inline-flex;
  border: 2px solid #eeeeee;
  padding: 5px;
  background-color: #fafafa;
  max-height: 500px;
  max-width: 500px;
  box-Sizing: border-box;
  margin: 10px !important;
  .thumbInner {
    position: relative;
    display: flex;
    justify-content: center; /* align horizontal */
    min-Width: 0;
    overflow: hidden;
    margin: 10px !important;
  }
}
.onlineShow {
  position: absolute;
  background-color: #1bd880 ;
  left:0;
  right:0;
  font-size: large;
  color: white;
  opacity:0.75;
}
.onlineHide {
  display: none;
}

@media only screen and (max-width: 800px) {
  .dropzone-thumb {
    display: inline-flex;
    border: 1px solid #eaeaea;
    max-height: 225px;
    max-width: 225px;
    box-Sizing: border-box;
    margin: 10px !important;
    .dropzone-thumbInner {
      display: flex;
      justify-content: center; /* align horizontal */
      min-Width: 0;
      overflow: hidden;
      margin: 5px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .dropzone-thumb {
    display: inline-flex;
    border: 1px solid #eaeaea;
    max-height: 150px;
    max-width: 150px;
    box-Sizing: border-box;
    margin: 10px !important;
    .dropzone-thumbInner {
      display: flex;
      justify-content: center; /* align horizontal */
      min-Width: 0;
      overflow: hidden;
      margin: 5px !important;
    }
  }
}
.resultsThumbsOutter {
  width: 70%;
  margin: auto;
}
.resultsMessage{
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 26px;
}

.errorMessage{
  width: 70%;
  margin: auto;
  text-align: center;
  font-size: 16px;
}

.btn {
  font-size: 20px !important;
}

